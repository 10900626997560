import React from 'react'
import mixpanel from 'mixpanel-browser'
import { Link } from 'gatsby'
import _ from 'lodash'
import { IconContext } from 'react-icons'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { IoIosArrowRoundForward } from 'react-icons/io'

import ProductCard from '../common/ProductCard'
import SectionHeader from '../common/SectionHeader'

import { connect } from 'react-redux'
import { toggleHubspotModalReportSubscription } from '../../../redux/actions/modals'

class InsightsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchbarCollapsed: true,
      sortBy: 'hot',
      activePage: 1,
      viewTutorialModal: false,
    }

    const boundMethods = ['changeSearchBarState', 'handleSearchChange', 'handleSearchReset']
    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })

    this.swipeDirection = 'swipe-left'
  }

  /*************************** lifecycle methods ***************************/

  componentWillMount() {
    // this.props.fetchMainReports();
  }

  showNoSearchResults() {
    return (
      <div className="noSearchResultsContainer">
        <div className="noSearchResultsHeader">0 results for "{this.props.searchQuery}"</div>
        <div className="noSearchResultsBodyContainer">
          <div className="noSearchResultsText">
            <div>Don't see what you're looking for?</div>
            <div>Run a Survey!</div>
          </div>
        </div>
      </div>
    )
  }

  /****************************** outer scope ******************************/

  /***************************** search controls *****************************/

  handleSearchChange(searchQuery) {
    this.props.storeSearchQuery(searchQuery)
  }

  handleSearchReset() {
    this.props.storeSearchQuery('')
  }

  /*
   * Called when the state of the search bar changes.
   */
  changeSearchBarState(newState) {
    if (!newState) {
      mixpanel.track('Opened Search Bar')
    }
    this.props.toggleSearchbar(this.props.searchbarCollapsed)
  }

  /*************************** internal navigation ****************************/

  /******************************* rendering ********************************/

  /*
   * what is displayed to the users if they have no survey cards in
   * either of the 'answer' or 'analyze' views
   */

  renderOverview() {
    const { individualPage, reports } = this.props

    const byTags = reports.reduce((acc, report) => {
      const { tags = [] } = report
      tags.forEach(tag => {
        acc[tag] = acc[tag] || []
        acc[tag].push(report)
      })
      return acc
    }, {})

    // important that this is _undefined_ and not _null_. slice() behaves differently
    const reportsLimit = individualPage ? undefined : 3

    return (
      <div className="mainContent">
        {/*<Searchbar
          searchQuery={this.state.searchQuery}
          handleSearchChange={this.handleSearchChange}
          customClass="darkTheme"
          iconRight
        />*/}
        {!individualPage && (
          <SectionHeader
            title="Access the reliable research you need today."
            subtitle="Everything you need to know to understand, engage and connect with today's college student."
            noUnderline
          />
        )}
        {individualPage && (
          <Link
            to="/marketplace/insights"
            className="is-flex-centered align-self-start"
            style={{ marginBottom: 10 }}
          >
            <IconContext.Provider value={{ color: 'white', size: '2em' }}>
              <IoIosArrowRoundBack />
            </IconContext.Provider>
            <p className="is-family-primary has-text-white">Back to Browse</p>
          </Link>
        )}
        {Object.entries(byTags).map(([tag, reports]) => [
          <SectionHeader title={tag} key="sectionHeader" />,
          <div className="columns is-multiline is-variable is-6-tablet is-0-mobile" key="cards">
            {reports.slice(0, reportsLimit).map(report => (
              <ProductCard
                {...report}
                buttonText="Access Report"
                short
                key={report._id}
                route={null /*`/products/insights/report/${report._id}`*/}
                onClick={this.props.toggleHubspotModalReportSubscription}
                section="reports"
              />
            ))}
          </div>,
          ...(individualPage
            ? []
            : [
                <Link
                  to={`/marketplace/insights/tag/${_.kebabCase(tag)}`}
                  className="has-text-white is-family-primary is-flex-centered align-self-start"
                  key="exploreAll"
                >
                  Explore all {tag}
                  <IconContext.Provider value={{ color: 'white', size: '2em' }}>
                    <IoIosArrowRoundForward />
                  </IconContext.Provider>
                </Link>,
              ]),
          <div style={{ marginBottom: 20 }} key="spacer" />,
        ])}
      </div>
    )
  }

  renderSearchContents() {
    return <p>{this.props.searchQuery}</p>
  }

  render() {
    const isSearching = this.props.searchQuery && this.props.searchQuery !== ''

    return (
      <div className="productContainer">
        <div />
        {!isSearching && this.renderOverview()}
        {isSearching && this.renderSearchContents()}
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  trending: [], // state.reports.trending,
  new: [], // state.reports.new,
  // searchQuery: state.searchbar.searchQuery
})

export default connect(mapStateToProps, {
  // toggleSearchbar,
  // storeSearchQuery,
  // fetchMainReports,
  toggleHubspotModalReportSubscription,
})(InsightsPage)
